import { successAlert, errorAlert, infoAlert } from '@/ort-lib/utils/utils';
import { axiosCore } from '@/plugins/axios';
import { store } from '@/store/index.js';

const state = {
  // profile info
  jobPosition: '',
  cardLocation: '',
  country: '',
  profileBio: '',
  profileImage: '',
  profileInfoCityId: '',
  profileInfoCityName: '',
  // investments
  latestInvestments: [],
  latestInvestmentsAvatar: [],
  isLatestInvestmentsLoading: false,
  isSuccessfullyFetchedLatestInvestments: false,
  // communication
  company: '',
  video: '',
  linkedin: '',
  twitter: '',
  facebook: '',
  instagram: '',
  accountAdminOptions: [],
  // teamMembers
  teamMembers: [],
  isTeamMembersLoading: false,
  isAddNewTeamMemberLoading: false,
  isLoadMoreBtnShown: false,
  teamMemberPaginationPage: 1,
  investorProfiles: [],
  entityCategorization: '',
  name: '',
  names: [],
  displayName: '',
  investorId: null,
};
const getters = {
  // teamMembers
  teamMembers: (state) => state.teamMembers,
  getInvestorName: (state) => state.name,
  getDisplayName: (state) => state.displayName,
  isLoadMoreBtnShown: (state) => state.isLoadMoreBtnShown,
  isTeamMembersLoading: (state) => state.isTeamMembersLoading,
  isAddNewTeamMemberLoading: (state) => state.isAddNewTeamMemberLoading,
  teamMemberPaginationPage: (state) => state.teamMemberPaginationPage,
  investorProfiles: (state) => state.investorProfiles,
  // profile info
  linkedin: (state) => state.linkedin,
  twitter: (state) => state.twitter,
  facebook: (state) => state.facebook,
  instagram: (state) => state.instagram,
  company: (state) => state.company,
  accountAdminOptions: (state) => state.accountAdminOptions,
  video: (state) => state.video,
  profileBio: (state) => state.profileBio,
  profileImage: (state) => state.profileImage,
  profileInfoCityName: (state) => state.profileInfoCityName,
  jobPosition: (state) => state.jobPosition,
  cardLocation: (state) => state.cardLocation,
  country: (state) => state.country,
  // investments
  latestInvestments: (state) => state.latestInvestments?.slice(0, 4),
  latestInvestmentsAvatar: (state) => state.latestInvestmentsAvatar,
  isLatestInvestmentsLoading: (state) => state.isLatestInvestmentsLoading,
  isSuccessfullyFetchedLatestInvestments: (state) => state.isSuccessfullyFetchedLatestInvestments,
  // communication
  entityCategorization: (state) => state.entityCategorization,
  getInvestorId: (state) => state.investorId,
};
const actions = {
  async getTeamMembers({ commit, state, rootGetters }) {
    const userId = rootGetters.accesstokenPayload.user_id;
    const relationshipId = rootGetters.accesstokenPayload?.active_profile?.relationship_id;
    const baseURL = `investors/user/${userId}/investor-relationship/${relationshipId}`;
    const page = (pageNumber) => { if (pageNumber != null) { return `?page=${pageNumber}`; } return ''; };
    const URL = (pageNumber) => `${baseURL}/team-members${page(pageNumber)}`;
    commit('setIsTeamMembersLoading', true);
    try {
      const response = await axiosCore.get(URL(state.teamMemberPaginationPage));
      if (
        response.data.count
        && response.data.next
        && response.data.next !== state.teamMemberPaginationPage
      ) {
        commit('setTeamMemberPaginationPage', response.data.next);
      }

      if (response.data.results || response.data.count > state.teamMembers.length) {
        commit('setTeamMembers', response.data.results);
      }

      if (response.data.next && response.data.count > state.teamMembers.length) {
        commit('setIsLoadMoreBtnShown', true);
      } else {
        commit('setIsLoadMoreBtnShown', false);
      }

      if (response.data.count <= state.teamMembers.length) {
        commit('setTeamMemberPaginationPage', null);
      }
      commit('setIsTeamMembersLoading', false);
    } catch (e) {
      commit('setIsTeamMembersLoading', false);
      console.error(e);
    }
  },
  updateInvestorCategorization({ dispatch, getters }, data) {
    const investorId = getters.portfolioInvestorId;
    return new Promise((resolve, reject) => {
      axiosCore.patch(`/investors/${investorId}/category`, data).then(
        (response) => {
          infoAlert('Successfully changed Categorization!');
          resolve(response.data);
        },
        (error) => {
          errorAlert(null, error);
          reject(error);
        },
      );
    });
  },
  investorNameChoices({ commit }, investorId) {
    const baseUrl = `/investors/${investorId}/display-name-choices`;
    return new Promise((resolve, reject) => {
      axiosCore.get(baseUrl).then(
        (response) => {
          commit('setNames', response.data);
          commit('setInvestorId', investorId);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  updateInvestorDisplayNameChoicesIfIdChange({ rootState, state, dispatch }) {
    const currentId = rootState.activate_entity.activeEntity.entity_id;
    if (currentId !== state.investorId) {
      dispatch('investorNameChoices', currentId);
    }
  },
  async addNewTeamMember({ commit, dispatch, rootGetters }, payload) {
    commit('setIsAddNewTeamMemberLoading', true);
    const body = {
      ...payload,
      legal_documents: payload?.willBeCcdValue?.includes('legal_documents') || false,
      invoicing: payload?.willBeCcdValue?.includes('invoicing') || false,
    };

    delete body.willBeCcdValue;
    try {
      const relationshipId = rootGetters.accesstokenPayload?.active_profile?.relationship_id;
      await axiosCore.post(`/investors/user-investor-relationship/${relationshipId}/team-members`, body);
      commit('setIsAddNewTeamMemberLoading', false);
      commit('setEmptyTeamMembers');
      dispatch('getTeamMembers');
      infoAlert('New team member added successfully');
    } catch (e) {
      commit('setIsAddNewTeamMemberLoading', false);
      errorAlert('There was an error in the request');
      console.error(e);
    }
  },
  getLatestInvestments({ commit, getters }) {
    const id = getters.portfolioInvestorId;
    commit('setLatestInvestmentsLoading', true);
    axiosCore.get(`/investors/${id}/latest-investments`)
      .then(
        (response) => {
          commit('setLatestInvestmentsLoading', false);
          commit('setIsSuccessfullyFetchedLatestInvestments', true);
          commit('setLatestInvestments', response.data?.results);
          commit('setLatestInvestmentsAvatar', response.data?.results);
        },
        () => {
          commit('setLatestInvestmentsLoading', false);
          commit('setIsSuccessfullyFetchedLatestInvestments', false);
        },
      );
  },
  getInvestorInfo({ commit }, body) {
    const baseUrl = `/investors/user/${body.userId}/investor-relationship/${body.relationId}/investor-info`;
    return new Promise((resolve, reject) => {
      axiosCore.get(baseUrl).then(
        (response) => {
          commit('setDisplayName', response.data.entity_display_name);
          commit('setLocation', response.data.city);
          commit('setCountry', response.data.country);
          commit('setInvestorLocation', response.data.city);
          commit('setLinkedin', response.data.linkedin);
          commit('setInstagram', response.data.instagram);
          commit('setFacebook', response.data.facebook);
          commit('setTwitter', response.data.twitter);
          commit('setCompany', response.data.employer);
          commit('setjobPosition', response.data.job_position);
          commit('setInvestorJob', response.data.job_position);
          commit('setProfileImage', response.data.user_profile_image);
          commit('setProfileInfoCityName', response.data.city);
          commit('setName', response.data.entity_name); // change to name when endopoint is in dev
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getInvestorDescription({ commit }, body) {
    const baseUrl = `/investors/user/${body.userId}/investor-relationship/${body.relationId}/investor-description`;
    return new Promise((resolve, reject) => {
      axiosCore.get(baseUrl).then(
        (response) => {
          commit('setVideo', response.data.video_url);
          commit('setProfileBio', response.data.bio);
          commit('setInvestorBio', response.data.bio);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getCategorization({ commit }, body) {
    const baseUrl = `/investors/${body.entityId}/category`;
    return new Promise((resolve, reject) => {
      axiosCore.get(baseUrl).then(
        (response) => {
          commit('setEntityCategorization', response.data.category);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  updateInvestorProfileInfo({ getters, commit, dispatch }, body) {
    const data = {
      investor: {},
      contact_info: {
        links: {},
      },
    };
    // profile info
    data.investor.bio = getters.profileBio;
    data.investor.address = getters.cardLocation;
    data.investor.display_name = getters.getDisplayName;
    data.contact_info.city = state.profileInfoCityId;
    data.contact_info.position = getters.jobPosition;
    data.contact_info.employer = getters.company;
    data.contact_info.links.twitter = getters.twitter;
    data.contact_info.links.facebook = getters.facebook;
    data.contact_info.links.instagram = getters.instagram;
    data.contact_info.links.linkedin = getters.linkedin;
    const baseUrl = `/investors/user/${body.userId}/investor-relationship/${body.relationId}/edit-profile`;
    return new Promise((resolve, reject) => {
      axiosCore.patch(baseUrl, data).then(
        (response) => {
          successAlert('Profile info successfully changed 📝');
          commit('setInvestorBio', getters.profileBio);
          commit('setInvestorJob', getters.jobPosition);
          commit('setInvestorLocation', getters.cardLocation);
          commit('setDisplayName', getters.getDisplayName);
          resolve(response);
        },
        (error) => {
          errorAlert('There was an error in the request');
          reject(error);
        },
      );
    });
  },
};

const mutations = {
  // profile info
  setAccountAdminOptions: (state, data) => (state.accountAdminOptions = data),
  setName: (state, data) => (state.name = data),
  setInvestorId: (state, id) => (state.investorId = id),
  setNames: (state, data) => (state.names = data),
  setDisplayName: (state, data) => (state.displayName = data),
  setjobPosition: (state, data) => (state.jobPosition = data),
  setLocation: (state, data) => (state.cardLocation = data),
  setCountry: (state, data) => (state.country = data),
  setProfileInfoCityId: (state, data) => (state.profileInfoCityId = data),
  setProfileInfoCityName: (state, data) => (state.profileInfoCityName = data),
  setCompany: (state, data) => (state.company = data),
  setVideo: (state, data) => (state.video = data),
  setLinkedin: (state, data) => (state.linkedin = data),
  setTwitter: (state, data) => (state.twitter = data),
  setFacebook: (state, data) => (state.facebook = data),
  setInstagram: (state, data) => (state.instagram = data),
  setProfileBio: (state, data) => (state.profileBio = data),
  setProfileImage: (state, data) => (state.profileImage = data),
  // investments
  setLatestInvestments: (state, data) => (state.latestInvestments = data),
  setLatestInvestmentsAvatar: (state, data) => (state.latestInvestmentsAvatar = data),
  setLatestInvestmentsLoading: (state, data) => (state.isLatestInvestmentsLoading = data),
  setIsSuccessfullyFetchedLatestInvestments: (state, data) => (state.isSuccessfullyFetchedLatestInvestments = data),
  // team members
  setEmptyTeamMembers: (state) => (state.teamMembers = []),
  setTeamMembers: (state, data) => (state.teamMembers = [...state.teamMembers, ...data]),
  setIsLoadMoreBtnShown: (state, data) => (state.isLoadMoreBtnShown = data),
  setIsTeamMembersLoading: (state, data) => (state.isTeamMembersLoading = data),
  setIsAddNewTeamMemberLoading: (state, data) => (state.isAddNewTeamMemberLoading = data),
  setTeamMemberPaginationPage: (state, data) => (state.teamMemberPaginationPage = data),
  setInvestorProfiles: (state, data) => (state.investorProfiles = data),
  // categorization
  setEntityCategorization: (state, data) => (state.entityCategorization = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
