/* eslint-disable no-shadow */

import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

const state = {
  funds: [],
  currentFund: null,
  currentInvestmentOffer: null,
  fundListValuations: [],
  fundSummaryValuations: {},
  clubDealListValuations: [],
  clubDealSummaryValuations: {},
  investorHasInvestment: null,
  investorHasInvestmentLoading: false,
  investorHasReports: true, // TODO get this bool from backend
  investorHasClubdealInvestment: {
    EUR: 0,
    USD: 0,
  },
  currentCurrency: 'EUR',
  loadingPortfolioValue: {
    isLoadingSummaryValuationClubdeal: false,
    isLoadingSummaryValuationFund: false,
  },
  error: null,
  clubDealListValuationIsLoading: true,
  fundListValuationIsLoading: true,
  portfolioState: {
    currencyFilters: [],
    tableFilters: [],
    companiesData: [],
    chartData: [],
    totalInitial: null,
    totalLatest: null,
    realizedMultiple: '',
    unrealizedMultiple: '',
  },
};

const getters = {
  clubDealCompaniesData: (state) => {
    const viewPortfolioDealListData = [];
    state.clubDealListValuations.map((clubDealListValuation) => {
      viewPortfolioDealListData.push({
        name: clubDealListValuation.name,
        amount: clubDealListValuation.value_initial,
        fileUrl: clubDealListValuation.latest_report,
        imageSrc: clubDealListValuation.startup_logo,
        color: null, // TODO: Change color here
      });
    });

    return viewPortfolioDealListData;
  },
  fundCompaniesData: (state) => {
    const viewPortfolioFundListData = [];
    state.fundListValuations.map((fundListValuation) => {
      viewPortfolioFundListData.push({
        name: fundListValuation.name,
        amount: fundListValuation.percentage_distribution,
        fileUrl: fundListValuation.latest_report,
        imageSrc: fundListValuation.startup_logo,
        color: null, // TODO: Change color here
      });
    });

    return viewPortfolioFundListData;
  },
  getCurrentFundId: (state) => state.currentFund?.id,
  getCurrentFund: (state) => state.currentFund,
  clubDealChartData: (state) => {
    const chartData = [];
    state.clubDealListValuations.map((clubDealListValuation) => {
      chartData.push({
        label: clubDealListValuation.name,
        value: clubDealListValuation.value_initial,
      });
    });

    return chartData;
  },
  fundChartData: (state) => {
    const chartData = [];
    state.fundListValuations.map((fundListValuation) => {
      chartData.push({
        label: fundListValuation.name,
        value: fundListValuation.percentage_distribution,
      });
    });

    return chartData;
  },
  clubDealChartTotalAmount: (state) => state.clubDealListValuations
    .map((item) => item.value_initial)
    .reduce((a, b) => a + b, 0),
  fundChartTotalAmount: (state) => state.fundListValuations
    .map((item) => item.value_initial)
    .reduce((a, b) => a + b, 0),
  getClubDealStages: (state) => {
    const groupedByStageField = groupBy(state.clubDealListValuations, (x) => x.growth_stage.name);
    const stages = Object.keys(groupedByStageField);

    return Object.values(
      groupedByStageField,
    )
      .map((objs, key) => ({
        label: stages[key],
        value: sumBy(objs, 'value_initial'),
      }));
  },

  // Goes through the multiple clubDealListValuations' valuations
  // and formats new unique sector objects with name and totalValue properties,
  // where totalValue is a sum of all value_initial fields of a specific sector.
  getClubDealSectors: (state) => {
    const parsedSector = {};

    state.clubDealListValuations.forEach((valuation) => {
      if (valuation.sectors.length === 0) {
        return;
      }
      const firstSector = valuation.sectors[0];
      if (parsedSector[firstSector.name]) {
        parsedSector[firstSector.name].value += valuation.value_initial;
      } else {
        parsedSector[firstSector.name] = {
          label: firstSector.name,
          value: valuation.value_initial,
        };
      }
    });

    const objectToList = Object.keys(parsedSector).map((key) => parsedSector[key]);
    return objectToList;
  },
  getClubDealChartLineData: (state) => {
    const chartData = [
      [
        {
          value: state.clubDealSummaryValuations.total_initial,
        },
        {
          value: state.clubDealSummaryValuations.total_latest,
        },
      ],
    ];

    return chartData;
  },
  getFundChartLineData: (state) => {
    const chartData = [
      [
        {
          value: state.fundSummaryValuations.total_initial,
        },
        {
          value: state.fundSummaryValuations.total_latest,
        },
      ],
    ];

    return chartData;
  },
  isLoadingPortfolioValue: (state) => {
    const loadingClubdeal = state.currentInvestmentOffer === 'Club deal'
      && state.loadingPortfolioValue.isLoadingSummaryValuationClubdeal;
    const loadingFund = state.currentInvestmentOffer !== 'Club deal'
      && state.loadingPortfolioValue.isLoadingSummaryValuationFund;
    return loadingClubdeal || loadingFund;
  },
  portfolioInvestorId: (state, getters) => {
    if (getters.isActiveCorporate) return getters.getCorporateInvestorId;
    if (getters.isActivePartner) return getters.getActivePartnerClient.entity_id;
    return getters.entityId;
  },
  getCurrentCurrency: (state) => state.currentCurrency,
};

const mutations = {
  setInvestorHasInvestment: (state, data) => {
    state.investorHasInvestment = data;
  },
  setInvestorHasInvestmentLoading: (state, data) => {
    state.investorHasInvestmentLoading = data;
  },
  setInvestorHasClubdealInvestmentEUR: (state, data) => {
    state.investorHasClubdealInvestment.EUR = data;
  },
  setInvestorHasClubdealInvestmentUSD: (state, data) => {
    state.investorHasClubdealInvestment.USD = data;
  },
  setFunds: (state, data) => {
    state.funds = data;
  },
  setCurrentFund: (state, data) => {
    state.currentFund = data;
  },
  setCurrentInvestmentOffer: (state, data) => {
    state.currentInvestmentOffer = data;
  },
  setFundListValuations: (state, data) => {
    state.fundListValuations = data;
  },
  setFundSummaryValuations: (state, data) => {
    state.fundSummaryValuations = data;
  },
  setClubDealListValuations: (state, data) => {
    state.clubDealListValuations = data;
  },
  setClubDealSummaryValuations: (state, data) => {
    state.clubDealSummaryValuations = data;
  },
  setCurrentCurrency: (state, data) => {
    state.currentCurrency = data;
  },
  setIsLoadingSummaryValuationClubdeal: (state, data) => {
    state.loadingPortfolioValue.isLoadingSummaryValuationClubdeal = data;
  },
  setIsLoadingSummaryValuationFund: (state, data) => {
    state.loadingPortfolioValue.isLoadingSummaryValuationFund = data;
  },
  setError: (state, data) => {
    state.error = data;
  },
  setClubDealListValuationIsLoading: (state, data) => {
    state.clubDealListValuationIsLoading = data;
  },
  setFundListValuationIsLoading: (state, data) => {
    state.fundListValuationIsLoading = data;
  },
};

const actions = {
  async fetchFunds({ commit, getters, state }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return; // Cant query yet
    if (state.funds.length) return; // Already made query
    try {
      const response = await axiosCore.get(`/investors/${investorId}/funds`);
      commit('setFunds', response.data);
      return;
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
    }
  },
  async fetchInvestorHasInvestmentFlag({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    commit('setInvestorHasInvestmentLoading', true);
    const url = `/investors/${investorId}/investor-has-investments`;
    try {
      const response = await axiosCore.get(url);
      commit('setInvestorHasInvestment', response.data.has_investments_eur);
      return;
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
      return;
    } finally {
      commit('setInvestorHasInvestmentLoading', false);
    }
  },
  async fetchInvestorHasClubdealInvestmentFlag({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    const url = `/investors/${investorId}/investor-has-investments?type=clubdeal`;
    try {
      const response = await axiosCore.get(url);
      commit('setInvestorHasClubdealInvestmentEUR', response.data.has_investments_eur);
      commit('setInvestorHasClubdealInvestmentUSD', response.data.has_investments_usd);
      return;
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
    }
  },
  async fetchFundListValuations({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    const fundInvestorId = getters.getCurrentFund?.investor_id;
    commit('setFundListValuationIsLoading', true);
    const url = `/investors/${investorId}/fund/${fundInvestorId}/list-valuations?currency=${state.currentCurrency}`;
    try {
      const response = await axiosCore.get(url);
      commit('setFundListValuations', response.data);
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
    } finally {
      commit('setFundListValuationIsLoading', false);
    }
  },
  async fetchFundSummaryValuations({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    const fundId = getters.getCurrentFundId;
    commit('setIsLoadingSummaryValuationFund', true);
    const url = `/investors/${investorId}/fund/${fundId}/summary-valuation?currency=${state.currentCurrency}`;
    try {
      const response = await axiosCore.get(url);
      commit('setFundSummaryValuations', response.data);
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
    } finally {
      commit('setIsLoadingSummaryValuationFund', false);
    }
  },
  async fetchClubDealListValuations({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    commit('setClubDealListValuationIsLoading', true);
    const url = `/investors/${investorId}/list-valuations-clubdeal?currency=${state.currentCurrency}`;
    try {
      const response = await axiosCore.get(url);
      commit('setClubDealListValuations', response.data);
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
    } finally {
      commit('setClubDealListValuationIsLoading', false);
    }
  },
  async fetchClubDealSummaryValuations({ commit, dispatch, getters }) {
    const investorId = getters.portfolioInvestorId;
    if (!investorId) return;
    commit('setIsLoadingSummaryValuationClubdeal', true);
    const url = `/investors/${investorId}/summary-valuations-clubdeal?currency=${state.currentCurrency}`;
    try {
      const response = await axiosCore.get(url);
      commit('setClubDealSummaryValuations', response.data);
    } catch (error) {
      commit('setError', 'Something went wrong.');
      errorAlert('Something went wrong', error);
      throw new Error(error.message);
    } finally {
      commit('setIsLoadingSummaryValuationClubdeal', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
