import { numberWithCommas } from '@/utils/number.js';

export function setCompactAmount(value, currency) {
  const suffixes = ['', 'K', 'M', 'B', 'T', ''];
  const currencies = {
    USD: '$', EUR: '€', GBP: '£', INR: '₹', JPY: '¥',
  };
  value = Math.round(value);
  const suffixNum = Math.floor(((`${value}`).length - 1) / 3);
  let shortValue = parseFloat((suffixNum != 0 ? (value / 1000 ** suffixNum) : value).toPrecision(8));
  if (shortValue % 1 != 0) {
    const decimals = value < 1_000_000 ? 0 : 1;
    shortValue = shortValue.toFixed(decimals);
  }
  let currencyPrefix = '';
  if (currencies.hasOwnProperty(currency)) {
    currencyPrefix = currencies[currency];
  }
  return `${currencyPrefix} ${shortValue}${suffixes[suffixNum]}`;
}

export default {
  methods: {
    setCompactAmount,
    getValueFromPath(path) {
      let value = this;
      for (let i = 0; i < path.length; i++) {
        const sub_path = path[i];
        value = value[sub_path];
      }
      return value;
    },
    formatString(str) {
      if (str !== undefined && str !== '') {
        const frags = str.split('_');
        for (let i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }
      return str;
    },
    formatAmount(amount, currency = 'EUR') {
      const number = numberWithCommas(amount) || 0;
      const symbol = currency === 'EUR' ? '€' : '$';
      return `${symbol}${number}`;
    },
    formatDate(date) {
      let formated_date = '';
      if (!this.isEmpty(date)) {
        if (typeof date !== 'string') {
          formated_date = date.toISOString().split('T')[0];
        } else {
          formated_date = date.split('T')[0];
        }
      }
      return formated_date;
    },
    isEmpty(value) {
      if (value === '' || value === null || value === undefined || value.length === 0) {
        return true;
      }
      return false;
    },
    isFileDownloadSupported() {
      const platformNavigator = navigator?.userAgentData?.platform;
      const isIOSDevice = /iPad|iPhone|iPod/.test(platformNavigator) || (platformNavigator === 'MacIntel' && navigator.maxTouchPoints > 1);
      const isAndroidDevice = /Android/.test(platformNavigator);
      return !isIOSDevice && !isAndroidDevice;
    },
    disableDashlaneAutocomplete(attribute, value) {
      const selector = `[${attribute}="${value}"]`;
      const input = document.querySelector(selector);
      if (!this.isEmpty(input)) {
        input.setAttribute('autocomplete', 'off');
        input.setAttribute('type', 'avoidAutocomplete');
        input.removeAttribute('data-kwimpalastatus');
        input.removeAttribute('data-kwimpalaid');
      }
    },
    getErrorResponse(error) {
      if (error.response != undefined) {
        return error.response;
      }
      return error;
    },
  },
};
