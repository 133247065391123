// Store index

// Required imports
import { createStore } from 'vuex';
import state from './state.js'; // To be depricated
import { mutations } from './mutations.js'; // To be depricated
import actions from './actions.js'; // To be depricated
import getters from './action_getters.js'; // To be depricated

import jwt from '@/ort-lib/stores/accounts/jwt.js';
import fundraisingPush from './modules/fundraising_push';
import singleFundraisingPush from './modules/single_fundraising_push';
import relationship_investor from './modules/entity_management/relationship_investor';
import relationship_corporate from './modules/entity_management/relationship_corporate';
import relationship_partner from './modules/entity_management/relationship_partner';
import relationship_startup from './modules/entity_management/relationship_startup';
import activate_entity from './modules/entity_management/activate_entity';

import users from './modules/user_management/users';
import recoverPassword from './modules/user_management/recover_password';
import user_login from './modules/user_management/user_login.js';
import activateUser from './modules/user_management/activate_user';
import userCreate from './modules/user_management/user_create';
import contactInfo from './modules/user_management/contact_info';
import locations from './modules/generics/locations';
import choices from './modules/generics/choices';
import sectorsModule from './modules/generics/sectors';
import businessModels from './modules/generics/business_models';
import businessStages from './modules/generics/business_stages';
import goToMarkets from './modules/generics/go_to_markets';
import loading_create_profile from './modules/generics/loading_create_profile';
import latest_fundraising from './modules/latest_fundraising';
import users_lib from '@/ort-lib/stores/accounts/user.js';

import onboarding_entrepreneur_info from '@/store/modules/onboarding/entrepreneur_info';
import onboarding_entrepreneur_actions from '@/store/modules/onboarding/entrepreneur_actions';

import portfolio from '@/store/modules/portfolio/portfolio_companies';
import reporting from '@/store/modules/portfolio/reporting';

import investor_platform_subscription from '@/store/modules/entities/investor/investor_platform_subscription';
import partner_profile_info from '@/store/modules/entities/partner/partner_profile_info';
import investor_profile_info_edit from '@/store/modules/entities/investor/investor_profile_info_edit';
import investor_legal_information from '@/store/modules/entities/investor/investor_legal_information';
import partner_investor_legal_information from '@/store/modules/entities/partner/partner_investor_legal_information';
import investor_kyc_shareholders from '@/store/modules/entities/investor/investor_kyc_shareholders';
import partner_clients from '@/store/modules/entities/partner/partner_clients';

import partner_contract_info from '@/store/modules/partner/partner_contract_info';
import portfolio_management from '@/store/modules/portfolio_management';
import startup from '@/ort-lib/stores/entities/startup';
import fundraising from '@/store/modules/opportunities/fundraising';
import opportunities_store from '@/store/modules/opportunities/opportunities_store';
import opportunities_list_fundraising from '@/store/modules/opportunities/list_fundraising';
import countries from '@/store/modules/countries/index.js';
import kyc_fund from '@/store/modules/legal/kyc_fund.js';
import kyc_fund_step from '@/store/modules/legal/kyc_fund_step.js';
import kyc_fund_choices from '@/store/modules/legal/kyc_fund_choices.js';
import kyc_fund_tax_country from '@/store/modules/legal/kyc_fund_tax_country.js';
import kyc_fund_beneficial_owner from '@/store/modules/legal/kyc_fund_beneficial_owner.js';
import kyc_fund_documents from '@/store/modules/legal/kyc_fund_documents.js';
import kyc from '@/store/modules/legal/kyc.js';
import WebinarsAndArticles from './modules/webinars_and_articles.js';
import secondary from './modules/secondary/secondary.js';

import documents from '@/store/modules/documents/documents.js';
import perks from './modules/perks/perks.js';
import chart_data from './modules/portfolio_management/chart_data.js';
import ui_interactions from '@/store/modules/generics/ui_interactions.js';

// eslint-disable-next-line import/prefer-default-export
export const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    jwt,
    fundraisingPush,
    singleFundraisingPush,
    recoverPassword,
    locations,
    choices,
    goToMarkets,
    businessModels,
    businessStages,
    sectorsModule,
    activateUser,
    userCreate,
    contactInfo,
    portfolio,
    reporting,
    users,
    onboarding_entrepreneur_info,
    onboarding_entrepreneur_actions,
    loading_create_profile,
    latest_fundraising,
    relationship_investor,
    relationship_corporate,
    relationship_partner,
    relationship_startup,
    activate_entity,
    investor_platform_subscription,
    investor_profile_info_edit,
    investor_legal_information,
    partner_investor_legal_information,
    partner_profile_info,
    investor_kyc_shareholders,
    partner_clients,
    user_login,
    users_lib,
    partner_contract_info,
    portfolioManagement: portfolio_management,
    startup,
    fundraising,
    opportunities_list_fundraising,
    opportunitiesStore: opportunities_store,
    countries,
    kyc_fund,
    kyc_fund_step,
    kyc_fund_choices,
    kyc_fund_tax_country,
    kyc_fund_documents,
    kyc,
    documents,
    kyc_fund_beneficial_owner,
    WebinarsAndArticles,
    perks,
    secondary,
    chart_data,
    ui_interactions,
  },
});
