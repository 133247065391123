<template>
  <EditModal ref="modal" @close="closeModal">
    <template v-slot:header>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="modal-title">Invite client via email</h2>
      </div>
    </template>
    <template v-slot:main>
      <VeeForm @submit="saveChanges" ref="form" v-slot="{ meta }">
      <div class="form-invite-investor">
        <FormTextInput
          class="form-invite-investor__field"
          :iconIsShown="false"
          placeholder="First name"
          v-model="formInput.first_name"
          title="First name"
          name="first-name"
        />
        <FormTextInput
        class="form-invite-investor__field"
        :iconIsShown="false"
        placeholder="Last name"
        v-model="formInput.last_name"
        title="Last name"
        name="last-name"
        />
        <FormTextInput
          class="form-invite-investor__field"
          :iconIsShown="false"
          placeholder="Email"
          v-model="formInput.email"
          rules="required|email"
          title="Email"
          name="email"
        />
        <FormDropdown
            class="form-invite-investor__field"
            @update:modelValue="onMemberTypeChange"
            placeholder="Select"
            :modelValue="formInput.investor_type"
            :options="investorTypeOptions"
            title="Investor type"
            :filterable="false"
            :iconIsShown="false"
            name="investor-type"
          />
        </div>

        <hr>
        <div class="button-section">
          <Button
          class="button-section__btn"
          color="secondary"
          @click="closeModal">
          Cancel
        </Button>
        <Button
        :disabled="!meta.valid || !formInput.investor_type"
        :loading="loadingInviteInvestor"
        class="button-section__btn"
        >
        Invite client
      </Button>
    </div>
  </VeeForm>
    </template>
  </EditModal>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import EditModal from '@/components/molecules/modals/EditModal.vue';
import Button from '@/components/atoms/buttons/Button.vue';
import FormTextInput from '@/components/atoms/forms/FormTextInput.vue';
import FormDropdown from '@/components/atoms/forms/FormDropdown.vue';

export default {
  name: 'NewInvestorModal',
  components: {
    EditModal,
    Button,
    FormTextInput,
    FormDropdown,
  },
  emits: ['close-investor-modal'],
  data() {
    return {
      id: this.$store.getters.accesstokenPayload.active_profile.entity_id,
      formInput: {
        first_name: '',
        last_name: '',
        email: '',
        investor_type: '',
      },
      investorTypeOptions: [
        {
          label: 'Individual',
          value: 'individual',
        },
        {
          label: 'Family office',
          value: 'family_office',
        },
        {
          label: 'Institutional',
          value: 'institutional',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      loadingInviteInvestor:
        (state) => state.partner_clients.loadingInviteInvestor,
      requestInviteInvestorFinished:
        (state) => state.partner_clients.requestInviteInvestorFinished,
    }),
    ...mapGetters(['getActiveEntityId']),
    returnId() {
      return this.getActiveEntityId;
    },
  },
  watch: {
    isModalOpened(newValue) {
      // eslint-disable-next-line no-unused-expressions
      newValue === true && this.updateInvestorDisplayNameChoicesIfIdChange();
      return newValue;
    },
    requestInviteInvestorFinished() {
      this.$emit('close-investor-modal');
    },
  },
  methods: {
    ...mapActions(['partnerInvitesInvestor']),
    closeModal() {
      this.$emit('close-investor-modal');
    },
    saveChanges() {
      this.partnerInvitesInvestor(this.formInput);
    },
    onMemberTypeChange(v) {
      this.formInput.investor_type = v.value;
    },
  },

};
</script>

<style lang="scss" scoped>
:deep(.title) {
  margin-bottom: unset;
}
.button-section {
  margin-bottom: 17px;
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  &__btn {
    width: 100%;
    max-width: 334px;
    @media screen and (max-width: 520px) {
      width: 100%
    }
  }

}

.modal-title {
  margin-right: 15px;
  @extend .text-xl--semibold
}
.form-invite-investor{
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  &__field {
    max-width: 334px;
    width: 100%;
    margin: 16px 0;
    & :deep(.v-select) {
      margin: 0.5rem 0 0 0
    }

  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }

}

</style>
