const errorDict2String = (dict) => {
  const errors = [];
  for (const [key, value] of Object.entries(dict)) {
    if (Array.isArray(value)) errors.push(`${key}: ${value[0]}`);
    else errors.push(`${key}: ${value}`);
  }
  return errors.join('\n');
};

export const parseError = (defaultErrorString, error) => {
  defaultErrorString = defaultErrorString || "Something went wrong.";
  if (!error) return defaultErrorString;
  const errorData = error.response?.data || error.message;
  if (typeof errorData === 'string') if (!errorData.includes('Request failed with status code')) return errorData;
  if (typeof errorData === 'object' && !Array.isArray(errorData)) return errorDict2String(errorData);
  return defaultErrorString;
};

const currencyEquivalences = {
  USD: '$', EUR: '€', GBP: '£', INR: '₹', JPY: '¥',
};

export function numberWithCommas(x) {
  return x.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function currencySymbol(currency) {
  if (Object.prototype.hasOwnProperty.call(currencyEquivalences, currency)) {
    return currencyEquivalences[currency];
  }
  return '';
}

// ex: 200000 => €200,000
export function formatAmountWithCurrencyAndCommas(currency, amount) {
  amount = Math.round(amount * 100) / 100;
  return `${currencySymbol(currency)}${numberWithCommas(amount)}`;
}

// ex: 200000 => € 200,000 (space after currency)
export function formatAmountWithCurrencyCommasAndSpace(currency, amount) {
  amount = Math.round(amount * 100) / 100;
  return `${currencySymbol(currency)} ${numberWithCommas(amount)}`;
}

export const setAmountToThousands = function (amount, currency) {
  const fixedNumber = amount.toString().split('.')[0];
  if (fixedNumber.length <= 3) {
    return `${currencySymbol(currency)} ${fixedNumber}`;
  }
  const positionToCut = fixedNumber.length - 3;
  const unit = 'K';
  const absolutePlusDecimal = fixedNumber.slice(0, positionToCut);
  const absoluteLastDecimalNum = fixedNumber[fixedNumber.length - 3];
  const decimalIsZero = absoluteLastDecimalNum === '0';
  let finalNum = '';
  finalNum = formatAmountWithCurrencyCommasAndSpace(currency, absolutePlusDecimal);
  return `${finalNum} ${unit}`;
};

export function humanize (/** @type {string} */ str) {
  let result = '';
  for (const frag of str.split('_'))
    result += frag.charAt(0).toUpperCase() + frag.slice(1) + ' ';
  return result.trim();
}
