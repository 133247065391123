import { store } from '@/store';
import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

const state = {};

const getters = {};

const actions = {
  createUpdateStartup: ({ getters }) => {
    const { user_id } = getters.accesstokenPayload;
    const data = {};

    data.user_id = user_id;
    data.startup_name = getters.startupName;
    data.location = getters.startupLocation;
    data.website = getters.startupWebsite;
    data.linkedin = getters.startupLinkedin;
    data.sectors_of_interest = getters.businessSectorOfInterest;
    data.business_models = getters.businessModels;
    data.go_to_markets = getters.goToMarkets;
    data.tagline = getters.startupTagline;
    data.company_description = getters.startupDescription;
    data.competitors = getters.startupCompetitors;

    data.growth_stage = getters.startupStage;
    data.total_round = getters.startupFundraisingSize;
    data.revenue = getters.startupRevenue;
    data.city = getters.location;
    data.employer = getters.employer;
    data.position = getters.position;
    data.pitch_deck_document = getters.uploadId;

    return new Promise((resolve, reject) => {
      axiosCore.post(`/startups/user/${user_id}/onboarding`, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          errorAlert('There was an error in the request', error);
          reject(error);
        },
      );
    });
  },
  inviteFounders: ({ getters }, startupData) => {
    const data = {};
    data.startup_id = startupData.id;
    data.founders = getters.startupFounders;

    return new Promise((resolve, reject) => {
      if (data.founders.length > 0) {
        axiosCore.post(`/startups/${data.startup_id}/invite-founders`, data).then(
          () => {
            resolve();
          },
          (error) => {
            errorAlert('There was an error in the request', error);
            reject(error);
          },
        );
      }
      resolve();
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
