<template>
  <footer class="site-footer main-wrapper">
    <div class="footer-menu-container footer-media">
      <div class="footer-first-column">
        <div class="main-navbar-logo">
          <direct-link to="https://www.oneragtime.com/" external>
            <img class="image-logo" src="https://img.oneragtime.com/uploads/2018/08/logo-color.png" alt="OneRagtime">
          </direct-link>
        </div>
        <div class=" footer-social-media">
          <direct-link class="footer-icon" to="https://twitter.com/OneRagtime" external><i class="fab fa-twitter" aria-hidden="true"></i></direct-link>
          <direct-link class="footer-icon" to="https://www.linkedin.com/company/oneragtime" external><i class="fab fa-linkedin-in" aria-hidden="true"></i></direct-link>
        </div>
      </div>
      <!-- <div>
        <div class="footer-address">
          <span class="address">
            <p class="footer-email text-sm--bold">contact@oneragtime.com</p>
            <p>60 Rue de Londres, Paris</p>
            <p>Carrer de Rosa Sensat, 9, Barcelona</p>
          </span>
        </div>
      </div> -->
      <div>
        <direct-link to="https://careers.oneragtime.com/investors" external>
          <p class="footer-pages text-sm--semibold">Investors</p>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/startups/" external>
          <p class="footer-pages text-sm--semibold">Start ups</p>
        </direct-link>
      </div>
      <div>
        <direct-link to="https://www.oneragtime.com/portfolio-companies/" external>
          <p class="footer-pages text-sm--semibold">Portfolio companies</p>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/portfolio-support/" external>
          <p class="footer-pages text-sm--semibold">Portfolio support</p>
        </direct-link>
      </div>
      <div>
        <direct-link to="https://www.oneragtime.com/team/" external>
          <p class="footer-pages text-sm--semibold">Our team</p>
        </direct-link>
        <direct-link  to="https://www.oneragtime.com/community/" external>
          <p class="footer-pages text-sm--semibold">Our community</p>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/our-commitments/" external>
          <p class="footer-pages text-sm--semibold">Our commitments</p>
        </direct-link>
      </div>
      <div>
        <direct-link to="https://www.oneragtime.com/blog/" external>
          <p class="footer-pages text-sm--semibold">Blog</p>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/resources/" external>
          <p class="footer-pages text-sm--semibold">Resources</p>
        </direct-link>
      </div>
    </div>

    <hr class="footer-division">

    <div class="footer-menu-container footer-media mt-4">
      <div>
        <p class="display-sm--semibold">
          Subscribe to our newsletter
        </p>
        <p class="text-md--regular">
          Find out about our news, fundraisings announcements and more.
        </p>
      </div>
      <div class="mt-3">
        <a target="_blank" href="https://6155cf0e.sibforms.com/serve/MUIEAGr7bZp0qYcudchxI9zm_oNICnnS_upazc02hb5xQGTtNO4S_tJ4Mbn9Ykl0qC5OxY1udWcW32Ybhv2AbjyiAjjINikB_8X9FN9rY8Uz6-IWMeV_v6LV0-KntwsLj89i5u0YokNRaZnDRns3f1AKmgsEwBM4TiZ024eEcQa6a3I1YaNrapoHgoDxo95pYqxDw1vSXhOnyiYS">
          <span class="py-3 px-5 subscribe">
            SUBSCRIBE NOW&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;↗
          </span>
        </a>
      </div>
    </div>

    <hr class="footer-division">

    <div class="footer-menu-container footer-media mt-4">
      <div>
        <!-- <direct-link to="https://www.oneragtime.com/faq/" external>
          <span class="pr-3 footer-pages text-sm--semibold">FAQ</span>
        </direct-link> -->
        <direct-link to="https://www.oneragtime.com/privacy-policy/" external>
          <span class="pr-3 footer-pages text-sm--semibold">Privacy Policy</span>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/terms-of-use/" external>
          <span class="px-3 footer-pages text-sm--semibold">Terms of use</span>
        </direct-link>
        <!-- <direct-link to="https://cdn.prod.website-files.com/625588512e942a74d372f853/6310de1c257da3fc45bb7e16_OneRagtime%20-%20SFDR.pdf" external>
          <span class="px-3 footer-pages text-sm--semibold">ESG-EN</span>
        </direct-link> -->
        <!-- <direct-link to="https://cdn.prod.website-files.com/625588512e942a74d372f853/666ff141fe7de061e129a7ba_ESG%20French%20version.pdf" external>
          <span class="px-3 footer-pages text-sm--semibold">ESG-FR</span>
        </direct-link> -->
        <direct-link to="https://www.oneragtime.com/procedures/" external>
          <span class="px-3 footer-pages text-sm--semibold">Procedures</span>
        </direct-link>
        <direct-link to="https://www.oneragtime.com/contact/" external>
          <span class="px-3 footer-pages text-sm--semibold">Contact</span>
        </direct-link>
      </div>
      <div>
        <p class="footer-text-copyright text-sm--semibold">© Copyright {{ (new Date()).getFullYear() }} - OneRagtime SAS</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.site-footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  flex-shrink: 0;
  padding-top: 96px;
  padding-bottom: 96px;
  margin-top: auto;

  .footer-menu-container {
    width: 96%;
    margin: auto;
    @include make-flex(space-between, unset, row);

    .image-logo {
      border: 0;
      width: 135px;
      height: 32px;
    }
    .footer-social-media {
      display: flex;
      margin-top: 24px;
    }
    .footer-icon {
      width: 14px;
      height: 24px;
      color: $primary;
      margin-right: 25px;
    }
    .icon {
      font-size: 10px;
      width: 13px;
      height: 24px;
      margin: 4px 8px;
      color: $grey-200;
    }
    .footer-pages {
      color: $grey-200;
      &:hover {
        color: $primary;
      }
    }
  }
}

.footer-division {
  width: 100%;
}

.footer-text-copyright {
  color: $grey-color;
}

.subscribe {
  border: 1px solid $grey-200;
  border-radius: 15px;
  color: $grey-100;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $primary;
    border: 0px solid $grey-200;
  }
}
</style>
